<template>
    <div>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64" />
        </v-overlay>
        <v-toolbar flat>
			<v-icon left>mdi-content-paste</v-icon>
			<v-toolbar-title>CONTRATOS</v-toolbar-title>
			<v-divider class="mx-4" vertical></v-divider>			
			<v-spacer></v-spacer>
			<v-btn 
				color="blue" 
				small 
				dark 
				@click="openDialogContrato"
			>
				<v-icon>mdi-plus</v-icon>Nuevo contrato
			</v-btn>
        </v-toolbar>
        <v-toolbar flat>
			<v-row>
				<v-col cols="12" md="6">
					<v-text-field
						clearable
						label="Nombre del Contrato"
						type="search"
						v-model="search"
					/>
				</v-col>
				<v-col cols="12" md="3">
					<v-select
						label="Estado"
						clearable
						v-model="estado"
						:items="estados"
						item-text="description"
						item-value="status"
						@change="getContratos"
					/>
				</v-col>
			</v-row>		
		</v-toolbar>
        <v-data-table
			:headers="headers"
			:items="dataTable"
            :search="search"
			item-key="contrato_id"
			class="elevation-1"
		>
            <template v-slot:item.opcion="{item}">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                            icon
                            x-large
                            @click="verContrato(item)"
                        >
                        <v-icon color="error">mdi-file-pdf</v-icon>
                        </v-btn>
                    </template>
                    <span>Ver contrato</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                            icon
                            x-large
                            @click="editContrato(item)"
                        >
                        <v-icon color="orange">mdi-content-paste</v-icon>
                        </v-btn>
                    </template>
                    <span>Editar contrato</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                            icon
                            x-large
                            @click="updaEstadoContrato(item)"
                        >
                        <v-icon :color="item.estado ? 'red' :'success'">{{item.estado ? 'mdi-cancel' : 'mdi-check'}}</v-icon>
                        </v-btn>
                    </template>
                    <span>{{item.estado ? 'Deshabilitar' : 'Habilitar'}} contrato</span>
                </v-tooltip>

            </template>
        </v-data-table>
        <v-dialog 
            v-model="dialogContrato"              
            persistent
            fullscreen
            :retain-focus="false"            
            :no-click-animation="true"
        >
			<v-overlay :value="overlay">
				<v-progress-circular indeterminate size="64" />
			</v-overlay>
            <v-card>
                <v-col cols="12">

                    <div class="my-2 col-md-12">
                        <b>CAMPOS PREDETERMINADOS:</b>
                        <ul>
                            <li
                                v-for="(cm, x) in camposMapeo"
                                :key="x"
                            > 
                                [{{cm.tag}}] - <b>({{cm.nombre}})</b>
                            </li>
                        </ul>
                    </div>
                    <div class="mt-2 col-md-6">
                        <v-text-field
                            label="Nombre del contrato"
                            v-model="nombreContrato"
                            outlined
                            dense
                            :rules=required
                            hide-details="auto"
                        />
                    </div>
                    </v-col>
                    <div class="my-2 col-md-12">
                        <vue-editor v-model="formatoContrato" />
                    </div>
                    <v-col cols="12" class="text-center">
                        <v-btn
                            color="primary"
                            class="mx-1"
                            @click="guardarContrato"
                        >
                            <v-icon>mdi-floppy</v-icon> GUARDAR
                        </v-btn> 
                        <v-btn
                            color="default"
                            class="mx-1"
                            @click="closeDialogContrato"
                        >
                            <v-icon>mdi-cancel</v-icon> CERRAR
                        </v-btn> 
                    </v-col>
                </v-col>
            </v-card>          
        </v-dialog>
        <v-dialog 
            v-model="dialogPrevisualizaContrato"              
            persistent
            width="100%"
            heigth="900px"       
            :no-click-animation="true"
        >
			<v-overlay :value="overlay">
				<v-progress-circular indeterminate size="64" />
			</v-overlay>
            <v-card>
                <embed :src=dataPrevisualizaContrato type="application/pdf" width="100%" height="900px" />
                <v-col class='text-center'>
                    <v-btn 
                        class="text-center" 
                        color='default' 
                        @click="closeDialogPrevisualizaContrato"
                    >
                        <v-icon>mdi-cancel</v-icon> CERRAR
                    </v-btn>
                </v-col>        
            </v-card>  
            
        </v-dialog>
    </div>
    
</template>


<script>

import { mapState, mapMutations, mapActions } from 'vuex' 

import { VueEditor } from "vue2-editor";
import Vue from 'vue'

export default {
    name: "ContratosComponent",
    components:{

        VueEditor 
    },
    data: () => ({
        headers:[
            { text: "ID", align: "start", value: "contrato_crm_id" },
            { text: "Nombres", align: "start", value: "nombre" },
            { text: "Estado", align: "start", value: "estado" },
            { text: "Creado el", align: "start", value: "created_stamp" },
            { text: "Opciones", align: "start", value: "opcion" }			
        ],
        requiredRule:[
            v => !!v || 'El campo es requerido'
        ], 
        editor: null,
        estados:[
            {description: 'Habilitado', status: true},
            {description: 'Deshabilitado', status: false}
        ], 
        estado:true,
        camposMapeo:[],
        dataTable:[],
        formatoContrato:'',
        overlay:false,
        contratoCrmId:null,
        nombreContrato:null,
        dialogContrato:false,
        dataPrevisualizaContrato:null,
        dialogPrevisualizaContrato:false,
        search:null,
        required:[
            v => !!v || 'El campo es requerido'
        ],
    }),
    computed: {

        ...mapState("master", ["loadingBtn", "companies","urlApi","loadingTable", "tenantId", "user"]),

        ...mapState("crm", ["loadingBtn", "paramAlertQuestion"]),

    },
    methods:{

        ...mapMutations("master", ["setUrl","setLoadingTable","setTitleToolbar","setTenantId"]),

        ...mapActions("master", ["requestApi", "alertNotification"]),

        getCampos(){

            this.setUrl('contratos')
            this.overlay=true
            
            this.requestApi({
                method: 'GET',
                data: {
                    typeList: 'mapeoContrato'
                }
            }).then(res =>{

                this.camposMapeo = res.data.detail

            }).catch(()=>{
                this.overlay=false
            }).then(()=>{
                this.overlay=false
            })

        },

        getContratos(){

            this.setUrl('contratos')
            this.overlay=true

            this.requestApi({
                method: 'GET',
                data: {
                    typeList: 'contratos',
                    estado: this.estado
                }
            }).then(res =>{

                this.dataTable = res.data.detail

            }).catch(()=>{
                this.overlay=false
            }).then(()=>{
                this.overlay=false
            })

        },

        guardarContrato(){

            Vue.swal({
                html: 'Esta seguro de guardar el contrato?',
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Aceptar",
                cancelButtonText: "Cerrar",
                ...this.paramAlertQuestion
            }).then(result => {

                if (result.isConfirmed) {

                    this.setUrl('contratos')
                    this.overlay=true
                    
                    this.requestApi({
                        method: 'POST',
                        data: {
                            typeStore:'storeContrato',
                            contratoCrmId: this.contratoCrmId,
                            formato: this.formatoContrato,
                            nombre : this.nombreContrato
                        }
                    }).then(res =>{

                        console.log('res',res)

                        this.getContratos()

                        this.closeDialogContrato()
                        
                        this.alertNotification({
                            param:{
                                html: res.data.msg
                            }
                        })   

                    }).catch(()=>{
                        this.overlay=false
                    }).then(()=>{
                        this.overlay=false
                    })

                }

            })

        },

        openDialogContrato(){

            this.dialogContrato =true

        },

        closeDialogContrato(){

            this.dialogContrato = false
            this.formatoContrato  = null
            this.contratoCrmId = null

        },

        editContrato(contrato){

            const {formato, contrato_crm_id, nombre} = contrato

            this.formatoContrato  = formato
            this.contratoCrmId = contrato_crm_id
            this.nombreContrato = nombre
            this.dialogContrato =true

        },

        updaEstadoContrato(contrato){
            
            Vue.swal({
                html: `Esta seguro de ${contrato.estado ? 'deshabilitar' : 'habilitar'} el contrato?`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Aceptar",
                cancelButtonText: "Cerrar",
                ...this.paramAlertQuestion
            }).then(result => {

                if (result.isConfirmed) {

                    this.contratoCrmId = contrato.contrato_crm_id

                    this.setUrl('contratos')
                    this.overlay=true
                    
                    this.requestApi({
                        method: 'POST',
                        data: {
                            typeStore:'actualizaEstadoContrato',
                            contratoCrmId: this.contratoCrmId
                        }
                    }).then(res =>{

                        console.log('res',res)

                        this.getContratos()

                        this.closeDialogContrato()
                        
                        this.alertNotification({
                            param:{
                                html: res.data.msg
                            }
                        })   

                    }).catch(()=>{
                        this.overlay=false
                    }).then(()=>{
                        this.overlay=false
                    })

                }

            })

        },

        verContrato(contrato){

            this.setUrl('contratos')
            this.overlay=true

            this.requestApi({
                method: 'POST',
                data: {
                    typeStore:'downloadContrato',
                    contratoCrmId: contrato.contrato_crm_id
                }
            }).then(res =>{

                console.log('res',res)

                this.dataPrevisualizaContrato = res.data.msg
                this.dialogPrevisualizaContrato = true

            }).catch(()=>{
                this.overlay=false
            }).then(()=>{
                this.overlay=false
            })


        },

        closeDialogPrevisualizaContrato(){

            this.dataPrevisualizaContrato = false
            this.dialogPrevisualizaContrato = false

        },

    },
    created(){

        this.getCampos()
        this.getContratos()

    },
    mounted(){
     

    }
    
}


</script>

<style>
@import "~vue2-editor/dist/vue2-editor.css";

/* Import the Quill styles you want */
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

</style>